import { Link } from "gatsby"
import React, { useState, useEffect, useContext } from "react"
import Layout from "../components/Layout"
import { Helmet } from "react-helmet"


const SellInformation = () => {
  const [isMobile, setIsMobile] = useState(false)
  const checkScreenSize = () => {
    if (window.innerWidth <= 700) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  useEffect(() => {
    checkScreenSize()
    window.addEventListener("resize", checkScreenSize,   {passive:true})
  }, [])
  return (
    <>
        <Helmet htmlAttributes={{ lang: "en" }} title={'Data Security - Cubo'}>
      <meta name="description" content={'Cubo is committed to protecting and securing your data.'} />
      <link rel="canonical" href='https://cubo.to/sellinformation/'/>
    </Helmet>
    <Layout>
      <div
        className="sell-information-section"
        style={{
          display: "flex",
          justifyContent: "center",
          maxWidth: isMobile ? "100%" : "1024px",
          padding: isMobile ? "0 20px" : "",
          margin: isMobile ? "" : "20px auto",
        }}
      >
        <div style={{ maxWidth: "100%" }}>
          <h1
            style={{
              textAlign: "center",
              marginBottom: "24px",
              fontWeight: "500",
              fontSize: "20px",
              fontFamily:'Poppins-Bold'
            }}
          >
            Do Not Sell My Personal Information
          </h1>
          <p>
            This notice and the options below apply only to California
            residents.
          </p>
          <p>
            If you are a California resident, the California Consumer Privacy
            Act (“CCPA”) provides you with the right to opt out of the “sale” of
            your Personal Information.
          </p>
          <p>
            Please review our
            <span className="blueText " style={{}}>
              <Link to="/privacypolicy/">&nbsp;Privacy Policy&nbsp;</Link>
            </span>
            for a more detailed description of how we collect, use, and share
            the Personal Information of California residents in operating our
            business; your privacy rights as a California resident; and how to
            exercise your rights as a California resident. For the purposes of
            this notice, “Personal Information” has the meaning given in the
            CCPA, but does not include information exempted from the scope of
            the CCPA.
          </p>
          <p>
            Cubo generally does not directly sell your Personal Information in
            the conventional sense (i.e., for money). Like many companies,
            however, we use services that help deliver interest-based ads to you
            and may transfer Personal Information to business partners for their
            use. Making Personal Information (such as online identifiers or
            browsing activity) available to these companies may be considered a
            “sale” under the CCPA.
          </p>
          <p>
            To request that Cubo may not to “sell” your Personal Information,
            please submit the form below to 【contact@cubo.to】. Please
            note, some transfers of your Personal Information may not be
            considered “sales” and certain exemptions may apply under the CCPA.
            Your selection does not affect other sharing of your information, as
            outlined in our Privacy Policy. You will still see some advertising,
            regardless of your selection. Your selection is saved to this
            browser, on this device.
          </p>
          <p>
            If you have any other questions or requests, please contact
            【contact@cubo.to】.
          </p>

          <div>
            <p>Opt Out of the “Sale” of Your Personal Information</p>
            <p>
              Please confirm that you are a California resident and provide us
              with your email address and zipcode in order to opt out of the
              sale of your Personal Information
            </p>
            <p>
              By submitting this form, you affirm that the information you
              provide is accurate and agree that Cubo may contact you and
              collect additional information regarding your request, to confirm
              your email address, and/or to verify your identity.
            </p>
            <h5>I am a California resident</h5>
            <div style={{ fontSize: isMobile ? "12px" : "" }}>
              <div
                style={{ display: "flex", fontSize: isMobile ? "12px" : "" }}
              >
                <p style={{ width: "230px" }}>Email Address (required):</p>
                <input type="text" id="email" style={{ height: "90%" }} />
              </div>
              <div style={{ display: "flex" }}>
                <p style={{ width: "230px" }}>Zip Code (required):</p>
                <input
                  type="text"
                  id="zipcode"
                  style={{
                    height: "90%",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
    </>
  )
}
export default SellInformation
